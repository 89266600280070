@import url(https://fonts.maateen.me/adorsho-lipi/font.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import url('https://fonts.maateen.me/bangla/font.css'); */
/* @import url('https://fonts.maateen.me/siyam-rupali/font.css'); */
/* @import url('https://fonts.maateen.me/solaiman-lipi/font.css'); */
/* @import url('https://fonts.maateen.me/kalpurush/font.css'); */

body {
  font-family: 'AdorshoLipi', Arial !important;
  background-color: #ffffff;
}

.card-border{
  border-top: 2px solid #ffee00 !important;
}

.card-body-style{
  background-color: #777777af;
}

.font-24{
  font-size: 24px;
}

.font-22{
  font-size: 22px;
}

.font-20{
  font-size: 20px;
}

.font-18{
  font-size: 18px;
}

.font-16{
  font-size: 16px;
}

.font-15{
  font-size: 15px;
}

.font-14{
  font-size: 14px;
}

.font-12{
  font-size: 12px;
}

.nav-hover:hover{
  color: rgb(255, 217, 0) !important;
}

.print-area{
  width: 900px;
  margin: auto;
}

.print-area2{
  width: 900px;
  margin: auto;
}


.char-border{
  padding: 15px;
  border: 20px solid;
  border-image-source: url(/static/media/border.17a8118f.png);
  border-image-repeat: repeat;
  border-image-slice: 120;
}


.demo-wrap {
  overflow: hidden;
  position: relative;
}

.demo-bg {
  opacity: 0.1;
  position: absolute;
  width: 400px;
  margin-left: 220px;

}

.demo-content {
  position: relative;
}

.demo-wrap2 {
  overflow: hidden;
  position: relative;
}

.demo-bg2 {
  opacity: 0.1;
  position: absolute;
  width: 500px;
  margin-left: 180px;
  margin-top: 100px;
}

.demo-content2 {
  position: relative;
}

.editorClassName{
  border: 1px solid rgb(121, 121, 121);
  padding: 2px;
  min-height: 200px;
}

table, th, td {
  border:1px solid black;
}

.print-area{
  background-color: #FFF3E3;
}

.border-outside{
  border: 15px solid #A00000;
  
}

.border-print{
  border-bottom: dotted;
  border-width: 1px solid;
  width: 300px;
}

.App {
  
}

.custom-dropdown{
  color: white;
  background-color: #282c34;
  border-bottom: 1px solid white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media print{
  .print-btn{
    visibility:hidden;
  }
  .navbar{
    visibility:hidden;
  }
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

